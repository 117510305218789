import { useUserProfile } from './useUserProfile.js';
import { CUSTOMIZATION_SIDEBAR_SCREEN_PARAMETER } from '@/components/PlatformSidebar/constants.js';
import { THEME_PICKER_STATE_PARAMETER } from '@/constants/platform.js';
import { useRouter } from 'next/router';

export const useHasTemplateDrawer = () => {
  const userProfile = useUserProfile();

  const { query } = useRouter();
  const sidebarView = query[CUSTOMIZATION_SIDEBAR_SCREEN_PARAMETER];

  return (
    (Boolean(query[THEME_PICKER_STATE_PARAMETER]) ||
      (Boolean(sidebarView) && sidebarView !== 'closed')) &&
    Boolean(userProfile?.visitorCanEdit)
  );
};
