import { StyledTextFieldInput } from './StyledTextFieldInput.js';
import { StyledTextFieldInputGroup } from './StyledTextFieldInputGroup.js';
import { StyledTextFieldTextArea } from './StyledTextFieldTextArea.js';
import { styled } from '@/stitches/index.js';

export const StyledTextField = styled('div', {
  '&:focus': {
    borderColor: '$brandYellow !important',
  },
  backgroundColor: '$white20',
  borderBottomColor: '$brandBlack',
  borderColor: '$gray40',
  borderRadius: '$8',
  borderStyle: 'solid',
  borderWidth: '$1',
  color: '$gray60',
  display: 'flex',
  flexDirection: 'column',
  fontFamily: '$inter',
  justifyContent: 'center',
  minHeight: '$48',
  position: 'relative',
  textAlign: 'left',
  transition: 'borderBottomColor easeInOut 0.4s',
  [`${StyledTextFieldTextArea},${StyledTextFieldInput},${StyledTextFieldInputGroup}`]:
    {
      '&:focus': {
        borderColor: '$brandYellow !important',
      },
      fontFamily: '$inter',
      fontSize: '$16',
      lineHeight: 1.4,
    },
  variants: {
    hasError: {
      true: {
        borderColor: '$uiErrorRegular',
      },
    },
    hasFullyRoundedBorders: {
      true: {
        borderColor: '$gray40',
        borderRadius: '$8',
      },
    },
    isBorderless: {
      true: {
        background: 'transparent',
        borderWidth: '0 0 $1 0',
      },
    },
    isDisabled: {
      true: {
        borderColor: '$gray30',
      },
    },
    isHighlighted: {
      true: {
        borderColor: '$brandYellow',
      },
    },
    isNew: {
      true: {
        '& > label': {
          color: '$gray70',
          fontSize: '$14',
          fontWeight: 600,
          lineHeight: '$20',
          margin: 0,
        },
        '& button': {
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
        },
        '& input, & button': {
          height: '$56',
        },
        '& input, & textarea, & button': {
          '&:focus': {
            borderColor: '$gray70',
          },
          '&:hover': {
            borderColor: '$gray60',
          },
          borderColor: '$gray40',
          borderRadius: '$8',
          borderStyle: 'solid',
          borderWidth: '$1',
          transition: 'border 0.2s ease-in-out',
        },
        borderWidth: 0,
        gap: '$8',
      },
    },
  },
  width: '100%',
});
