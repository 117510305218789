import { createContext } from 'react';

export type FormFieldContext = {
  /**
   * The id of the error for aria linking.
   */
  errorAriaId?: string;
  hasError: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FormFieldContext = createContext<FormFieldContext>({
  hasError: false,
});
