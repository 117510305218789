import { type BreakpointNames } from '@/stitches/media.js';

export const PADDING_AT_BREAKPOINT: Record<BreakpointNames, number> = {
  bp0: 16,
  bp1: 32,
  bp2: 48,
  bp3: 72,
  bp4: 88,
  bp5: 104,
};

export const FULL_WIDTH_RESPONSIVE_NEGATIVE_MARGINS = {
  '@bp0': {
    margin: `0 -$${PADDING_AT_BREAKPOINT.bp0}`,
  },
  '@bp1': {
    margin: `0 -$${PADDING_AT_BREAKPOINT.bp1}`,
  },
  '@bp2': {
    margin: `0 -$${PADDING_AT_BREAKPOINT.bp2}`,
  },
  '@bp3': {
    margin: `0 -$${PADDING_AT_BREAKPOINT.bp3}`,
  },
  '@bp4': {
    margin: `0 -$${PADDING_AT_BREAKPOINT.bp4}`,
  },
  '@bp5': {
    margin: `0 -$${PADDING_AT_BREAKPOINT.bp5}`,
  },
};

export const FULL_WIDTH_RESPONSIVE_PADDING = {
  '@bp0': {
    padding: `0 $${PADDING_AT_BREAKPOINT.bp0}`,
  },
  '@bp1': {
    padding: `0 $${PADDING_AT_BREAKPOINT.bp1}`,
  },
  '@bp2': {
    padding: `0 $${PADDING_AT_BREAKPOINT.bp2}`,
  },
  '@bp3': {
    padding: `0 $${PADDING_AT_BREAKPOINT.bp3}`,
  },
  '@bp4': {
    padding: `0 $${PADDING_AT_BREAKPOINT.bp4}`,
  },
  '@bp5': {
    padding: `0 $${PADDING_AT_BREAKPOINT.bp5}`,
  },
};
