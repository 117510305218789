/* eslint-disable canonical/sort-keys */
/* eslint-disable typescript-sort-keys/interface */
import { Col, ColContainer } from './Column.styles.js';
import { type CSS } from '@stitches/react';
import { type ComponentProps, type ReactNode } from 'react';

export type WidthType =
  | 'auto'
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12;

const getColumnStyles = (value?: WidthType) => {
  switch (value) {
    case undefined:
      return {};
    case 0:
      return { display: 'none' };
    default:
      return { gridColumn: `span ${value}`, display: 'inherit' };
  }
};

const getCenteredColumnStyles = (value?: WidthType) => {
  if (value === undefined) {
    return {};
  }

  if (value === 'auto') {
    return getColumnStyles(value);
  }

  return {
    gridColumnStart: (12 - value) / 2 + 1,
    gridColumnEnd: 13 - (12 - value) / 2,
  };
};

export const Column = ({
  center,
  children,
  css,
  cssLg,
  cssMd,
  cssSm,
  cssXl,
  cssXxl,
  initial,
  lg,
  md,
  sm,
  xl,
  xxl,
  ...props
}: {
  readonly initial?: WidthType;
  readonly sm?: WidthType;
  readonly md?: WidthType;
  readonly lg?: WidthType;
  readonly xl?: WidthType;
  readonly xxl?: WidthType;
  readonly children?: ReactNode;
  readonly css?: CSS;
  readonly cssSm?: CSS;
  readonly cssMd?: CSS;
  readonly cssLg?: CSS;
  readonly cssXl?: CSS;
  readonly cssXxl?: CSS;
  readonly props?: {};
  readonly center?: Boolean;
}) => {
  return (
    <Col
      css={{
        '@bp0': {
          ...(center
            ? getCenteredColumnStyles(initial)
            : getColumnStyles(initial)),
          ...css,
        },
        '@bp1': {
          ...(center ? getCenteredColumnStyles(sm) : getColumnStyles(sm)),
          ...cssSm,
        },
        '@bp2': {
          ...(center ? getCenteredColumnStyles(md) : getColumnStyles(md)),
          ...cssMd,
        },
        '@bp3': {
          ...(center ? getCenteredColumnStyles(lg) : getColumnStyles(lg)),
          ...cssLg,
        },
        '@bp4': {
          ...(center ? getCenteredColumnStyles(xl) : getColumnStyles(xl)),
          ...cssXl,
        },
        '@bp5': {
          ...(center ? getCenteredColumnStyles(xxl) : getColumnStyles(xxl)),
          ...cssXxl,
        },
      }}
      {...props}
    >
      {children}
    </Col>
  );
};

export const ColumnContainer = ({
  children,
  ...props
}: ComponentProps<typeof ColContainer>) => {
  return <ColContainer {...props}>{children}</ColContainer>;
};
