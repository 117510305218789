import { TextFieldContext } from './context.js';
import { StyledTextField } from './styled/StyledTextField.js';
import { StyledTextFieldInputGroup } from './styled/StyledTextFieldInputGroup.js';
import { TextFieldInputItems } from './styled/StyledTextFieldInputItems.js';
import { StyledTextFieldLabel } from './styled/StyledTextFieldLabel.js';
import { StyledTextFieldMenu } from './styled/StyledTextFieldMenu.js';
import { StyledTextFieldTextArea } from './styled/StyledTextFieldTextArea.js';
import { TextFieldButton } from './TextFieldButton.js';
import { TextFieldChevron } from './TextFieldChevron.js';
import { TextFieldClearButton } from './TextFieldClearButton.js';
import { TextFieldDisabled } from './TextFieldDisabled.js';
import { TextFieldInput } from './TextFieldInput.js';
import { TextFieldInputAddon } from './TextFieldInputAddon.js';
import { TextFieldSelectionText } from './TextFieldSelectionText.js';
import { type CSS } from '@stitches/react';
import {
  type ComponentPropsWithoutRef,
  forwardRef,
  type Ref,
  useMemo,
  useState,
} from 'react';

type TextFieldProps = ComponentPropsWithoutRef<'div'> & {
  readonly css?: CSS;
  readonly hasError?: boolean;
  readonly hasFullyRoundedBorders?: boolean;
  readonly isBorderless?: boolean;
  readonly isDisabled?: boolean;
  readonly isHighlighted?: boolean;
  readonly variant?: 'default' | 'new';
};

const TextFieldCompound = forwardRef(
  (
    {
      children,
      hasError = false,
      hasFullyRoundedBorders = false,
      isBorderless = false,
      isDisabled = false,
      isHighlighted = false,
      variant = 'default',
      ...props
    }: TextFieldProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const [isHighlightedState, setIsHighlightedState] = useState(isHighlighted);
    const context = useMemo(() => {
      return {
        hasError,
        hasFullyRoundedBorders,
        isDisabled,
        isHighlighted: isHighlightedState,
        setIsHighlighted: setIsHighlightedState,
      };
    }, [hasError, hasFullyRoundedBorders, isDisabled, isHighlightedState]);

    return (
      <TextFieldContext.Provider value={context}>
        <StyledTextField
          hasError={hasError}
          hasFullyRoundedBorders={hasFullyRoundedBorders}
          isBorderless={isBorderless}
          isDisabled={isDisabled}
          isHighlighted={isHighlightedState}
          isNew={variant === 'new'}
          ref={ref}
          {...props}
        >
          {children}
        </StyledTextField>
      </TextFieldContext.Provider>
    );
  },
);

// eslint-disable-next-line prefer-object-spread
const TextField = Object.assign({}, TextFieldCompound, {
  Chevron: TextFieldChevron,
  ClearButton: TextFieldClearButton,
  Disabled: TextFieldDisabled,
  Input: TextFieldInput,
  InputAddon: TextFieldInputAddon,
  InputGroup: StyledTextFieldInputGroup,
  InputItems: TextFieldInputItems,
  Label: StyledTextFieldLabel,
  Menu: StyledTextFieldMenu,
  MenuToggle: TextFieldButton,
  SelectionText: TextFieldSelectionText,
  TextArea: StyledTextFieldTextArea,
});

export { TextField };
