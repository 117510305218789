import { styled } from '@/stitches/index.js';

export const StyledFormField = styled('fieldset', {
  '&:disabled': {
    '& textarea': {
      backgroundColor: '$gray20',
      opacity: 0.5,
    },
  },
  position: 'relative',
  textAlign: 'left',
});
