import { styled } from '@/stitches/index.js';
import { LockOutlineIcon } from '@contra/icons/icons';

const StyledLockOutline = styled(LockOutlineIcon, {
  color: '$uiBlackDisabled',
  height: '$22',
  position: 'absolute',
  right: '$16',
  top: '$22',
  width: '$22',
});

export const TextFieldDisabled = () => {
  return (
    <StyledLockOutline
      color="$uiBlackDisabled"
      focusable={false}
      role="img"
    />
  );
};
