import { type ToastState } from './reducer.js';
import { type ToastType } from './types.js';

export const TOAST_LIMIT = 20;

export const DEFAULT_TIMEOUTS: {
  [key in ToastType]: number;
} = {
  content: 15_000,
  error: 4_000,
  info: 4_000,
  loading: 30_000,
  success: 2_000,
  warning: 4_000,
};

export const DEFAULT_STATE: ToastState = {
  pausedAt: undefined,
  toasts: [],
};
