import { FormFieldContext } from './context.js';
import { StyledFormFieldError } from './styled/StyledFormFieldError.js';
import { type ComponentPropsWithRef, useContext } from 'react';

type FormFieldErrorProps = ComponentPropsWithRef<'div'>;

const FormFieldError = ({ children }: FormFieldErrorProps) => {
  const { errorAriaId, hasError } = useContext(FormFieldContext);

  if (!hasError) return null;

  return (
    <StyledFormFieldError
      aria-labelledby={errorAriaId}
      role="alert"
    >
      <div id={errorAriaId}>{children}</div>
    </StyledFormFieldError>
  );
};

FormFieldError.displayName = 'FormField.Error';

export { FormFieldError };
