import { styled } from '@/stitches/index.js';

// This should be the inverse of input's horizontal padding.
const negativeInputPadding = '$4';

export const StyledTextFieldInputAddon = styled('div', {
  color: '$gray50',
  variants: {
    appended: {
      false: {
        marginRight: `-${negativeInputPadding} !important`,
      },
      true: {
        marginLeft: `-${negativeInputPadding} !important`,
      },
    },
  },
});
