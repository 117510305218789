import { useCurrentUser } from '@/hooks/useCurrentUser.js';
import { logger } from '@/services/logger.js';
import { type FeatureFlags } from '@/utilities/getDefaultFeatureFlags.js';
import getConfig from 'next/config';
import { type PostHog, posthog as posthogLibrary } from 'posthog-js';
import {
  createContext,
  type Dispatch,
  type FC,
  type MutableRefObject,
  type ReactNode,
  type SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

const log = logger.child({
  namespace: 'PosthogContext',
});

export type PostHogContext = {
  featureFlags: Readonly<Partial<FeatureFlags>>;
  posthog?: PostHog;
  setFeatureFlags: Dispatch<SetStateAction<Readonly<Partial<FeatureFlags>>>>;
  warnings?: MutableRefObject<string[]>;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostHogContext = createContext<PostHogContext | null>(null);

type PostHogProviderProps = {
  readonly children: ReactNode | ReactNode[] | null;
};

const { publicRuntimeConfig } = getConfig();
const { posthogApiToken } = publicRuntimeConfig;

export const PostHogProvider: FC<PostHogProviderProps> = ({
  children,
}: PostHogProviderProps) => {
  const currentUser = useCurrentUser();
  const { featureFlags: visitorFeatureFlags } = currentUser ?? {};
  const [featureFlags, setFeatureFlags] = useState<
    Readonly<Partial<FeatureFlags>>
  >(visitorFeatureFlags ?? {});

  const warnings = useRef<string[]>([]);

  useEffect(() => {
    if (
      posthogApiToken &&
      currentUser?.areAnalyticsEnabled &&
      !posthogLibrary.__loaded
    ) {
      posthogLibrary.init(posthogApiToken, {
        autocapture: true,
        bootstrap: {
          // This ensures we don't generate a new anonymous ID each time we reload the page.
          distinctID: currentUser?.analyticsUserId
            ? currentUser?.analyticsUserId
            : currentUser.sessionId
              ? currentUser.sessionId
              : undefined,
          featureFlags,
          isIdentifiedID: Boolean(currentUser?.analyticsUserId),
        },
        capture_pageview: false,
        // this is enabled manually after posthog is initialized
        disable_session_recording: true,
        loaded: () => {
          log.info('Loaded Posthog');
          if (currentUser?.analyticsUserId) {
            log.info(
              { analyticsUserId: currentUser?.analyticsUserId },
              'Posthog.identify non-anonymous user',
            );
            posthogLibrary.identify(currentUser?.analyticsUserId);
          }

          // Delaying recording reduces script execution time in the critical rendering path.
          posthogLibrary.startSessionRecording();
        },
        mask_all_element_attributes: false,
        mask_all_text: false,
        persistence: 'localStorage',
        session_recording: {
          maskAllInputs: false,
        },
      });
    }
  }, [currentUser, featureFlags]);

  const context = useMemo(() => {
    return {
      featureFlags,
      setFeatureFlags,
      warnings,
    };
  }, [featureFlags]);

  return (
    <PostHogContext.Provider value={context}>
      {children}
    </PostHogContext.Provider>
  );
};
