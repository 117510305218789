import { styled } from '@/stitches/index.js';

export const StyledTextFieldInput = styled('input', {
  '&::placeholder': {
    color: '$gray60',
    opacity: '1',
  },
  '&:disabled': {
    background: 'none',
  },
  '&:first-child,&:first-child:focus': {
    marginLeft: '0',
    paddingLeft: '$12',
  },
  '&:focus': {
    /* This is required because of the CSS reset */
    marginLeft: '0',
    marginRight: '0',
    paddingLeft: '$4',
    paddingRight: '$4',
  },
  '&:last-child:focus,&:last-child': {
    marginRight: '0',
    paddingRight: '$16',
  },
  backgroundColor: 'transparent',
  border: '0',
  borderRadius: 'inherit',
  flex: '1',
  fontFamily: '$inter',
  fontSize: '$16',
  height: '$36',
  lineHeight: 1.5,
  margin: '0',
  outline: 'none',
  padding: '0 $4',
  width: '100%',
});
