import { createContext, type Dispatch, type SetStateAction } from 'react';

export type TextFieldContext = {
  hasError: boolean;
  isDisabled: boolean;
  isHighlighted: boolean;
  setIsHighlighted: Dispatch<SetStateAction<boolean>>;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TextFieldContext = createContext<TextFieldContext>({
  hasError: false,
  isDisabled: false,
  isHighlighted: false,
  setIsHighlighted: () => {},
});
