import { styled } from '@/stitches/index.js';

export const StyledTextFieldLabel = styled('label', {
  color: '$gray60',
  fontSize: '$12',
  fontWeight: 400,
  justifySelf: 'flexStart',
  lineHeight: '1.2',
  margin: '$7 0 0 $12',
});
