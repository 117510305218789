import {
  FULL_WIDTH_RESPONSIVE_NEGATIVE_MARGINS,
  PADDING_AT_BREAKPOINT,
} from './constants.js';
import {
  Column,
  ColumnContainer,
  type WidthType,
} from '@/components/Column/Column.js';
import { Section as BaseSection } from '@/components/Section/Section.js';
import { useHasTemplateDrawer } from '@/hooks/useHasTemplateDrawer.js';
import { styled } from '@/stitches/index.js';
import { pxToRem } from '@/utilities/pxToRem.js';
import { type CSS } from '@stitches/react';
import { type ComponentProps, type ElementType, type ReactNode } from 'react';
import { type PolymorphicPropsWithoutRef } from 'react-polymorphic-types';

const Container = styled('div', {
  '@bp0': {
    margin: `0 $${PADDING_AT_BREAKPOINT.bp0}`,
  },
  '@bp1': {
    margin: `0 $${PADDING_AT_BREAKPOINT.bp1}`,
  },
  '@bp2': {
    margin: `0 $${PADDING_AT_BREAKPOINT.bp2}`,
  },
  '@bp3': {
    margin: `0 $${PADDING_AT_BREAKPOINT.bp3}`,
  },
  '@bp4': {
    margin: `0 $${PADDING_AT_BREAKPOINT.bp4}`,
  },
  '@bp5': {
    margin: `0 $${PADDING_AT_BREAKPOINT.bp5}`,
  },
  position: 'relative',
});

const FullWidthContainer = styled('div', {
  variants: {
    hasTemplateDrawer: {
      true: {
        '@bp2': {
          width: `calc(100vw - ${pxToRem(336)})`,
        },
        transition: `width 750ms ease`,
      },
    },
  },
  width: '100vw',
  ...FULL_WIDTH_RESPONSIVE_NEGATIVE_MARGINS,
});

type PlatformButtonProps<C extends ElementType = 'div'> =
  PolymorphicPropsWithoutRef<ComponentProps<typeof FullWidthContainer>, C>;

const FullWidth = <C extends ElementType = 'div'>({
  children,
  ...props
}: PlatformButtonProps<C>) => {
  const hasTemplateDrawer = useHasTemplateDrawer();

  return (
    <FullWidthContainer
      {...props}
      hasTemplateDrawer={hasTemplateDrawer}
    >
      {children}
    </FullWidthContainer>
  );
};

const getLeftPadding = (containerPadding: number, columns: WidthType) => {
  // Calculates additional grid column padding for full width scrollable container.
  // Viewport width - Layout container padding / number of columns * desired column padding
  return `calc($${containerPadding} + (((100vw - ($${containerPadding} * 2)) / 12) * ${columns}))`;
};

const getPaddingStyles = (breakpointPadding: number, value?: WidthType) => {
  switch (value) {
    case undefined:
    case 0:
      return {
        paddingLeft: `$${breakpointPadding}`,
        paddingRight: `$${breakpointPadding}`,
      };
    default: {
      return {
        paddingLeft: getLeftPadding(breakpointPadding, value),
        paddingRight: `$${breakpointPadding}`,
      };
    }
  }
};

/*
  Using the full width container, adds responsive
  padding with the option to add extra column padding
  at the different breakpoints.
*/
const ScrollableChildWithPaddingRaw = ({
  children,
  initial,
  lg,
  md,
  sm,
  xl,
  xxl,
  ...props
}: {
  readonly children: ReactNode;
  readonly css?: CSS;
  readonly initial?: WidthType;
  readonly lg?: WidthType;
  readonly md?: WidthType;
  readonly sm?: WidthType;
  readonly xl?: WidthType;
  readonly xxl?: WidthType;
}) => {
  return (
    <FullWidth
      css={{
        '& > *': {
          '@bp0': getPaddingStyles(PADDING_AT_BREAKPOINT.bp0, initial),
          '@bp1': getPaddingStyles(PADDING_AT_BREAKPOINT.bp1, sm ?? initial),
          '@bp2': getPaddingStyles(
            PADDING_AT_BREAKPOINT.bp2,
            md ?? sm ?? initial,
          ),
          '@bp3': getPaddingStyles(
            PADDING_AT_BREAKPOINT.bp3,
            lg ?? md ?? sm ?? initial,
          ),
          '@bp4': getPaddingStyles(
            PADDING_AT_BREAKPOINT.bp4,
            xl ?? lg ?? md ?? sm ?? initial,
          ),
          '@bp5': getPaddingStyles(
            PADDING_AT_BREAKPOINT.bp5,
            xxl ?? xl ?? lg ?? md ?? sm ?? initial,
          ),
        },
      }}
      {...props}
    >
      {children}
    </FullWidth>
  );
};

const ScrollableChildWithPadding = styled(ScrollableChildWithPaddingRaw);

const Section = styled(BaseSection);

const Grid = styled(ColumnContainer);

const GridColumn = styled(Column);

const FlexColumn = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

const FlexRow = styled('div', {
  display: 'flex',
  flexDirection: 'row',
});

export const Layout = Object.assign(Container, {
  FlexColumn,
  FlexRow,
  FullWidth,
  Grid,
  GridColumn,
  ScrollableChildWithPadding,
  Section,
});
