import { letterSpacings } from './letterSpacings.js';
import { sizes } from './sizes.js';
import { createTheme } from './stitches.config.js';
import { remToPx } from '@/utilities/remToPx.js';

const transformObjectToPx = (object: Record<string, string>) => {
  return Object.fromEntries(
    Object.entries(object).map(([key, value]) => {
      return key.includes('safe')
        ? [key, key.replace('safe', '')]
        : [key, remToPx(value)];
    }),
  );
};

const pxSizes = transformObjectToPx(sizes);

export const nonFluidTheme = createTheme('nonFluidTheme', {
  fontSizes: pxSizes,
  height: pxSizes,
  letterSpacings: transformObjectToPx(letterSpacings),
  sizes: pxSizes,
  space: pxSizes,
});
