import { styled } from '@/stitches/index.js';
import { type ReactNode } from 'react';

type TextFieldSelectionTextProps = {
  readonly children: ReactNode;
  readonly isDisabled?: boolean;
  readonly isPlaceholder?: boolean;
  readonly label?: string;
};

const Span = styled('span', {
  color: '$gray50',
  fontSize: '$16',
});

export const TextFieldSelectionText = ({
  children,
  isDisabled,
  isPlaceholder,
  label,
}: TextFieldSelectionTextProps) => {
  return (
    <Span
      aria-label={label}
      css={{
        color: isPlaceholder || isDisabled ? '$gray50' : '$brandBlack',
        textAlign: 'left',
      }}
    >
      {children}
    </Span>
  );
};

TextFieldSelectionText.displayName = 'TextField.SelectionText';
