import { TextFieldContext } from './context.js';
import { StyledTextFieldInput } from './styled/StyledTextFieldInput.js';
import { FormFieldContext } from '@/components/FormField/context.js';
import { type CSS } from '@stitches/react';
import {
  type ComponentPropsWithoutRef,
  forwardRef,
  type Ref,
  useContext,
} from 'react';

type TextFieldInputProps = ComponentPropsWithoutRef<'input'>;

export const TextFieldInput = forwardRef(
  (
    { onChange, value, ...props }: TextFieldInputProps & { readonly css?: CSS },
    ref: Ref<HTMLInputElement>,
  ) => {
    const { hasError } = useContext(TextFieldContext);
    const { errorAriaId } = useContext(FormFieldContext);

    return (
      <StyledTextFieldInput
        aria-errormessage={errorAriaId}
        // We only want aria-invalid in the dom when true.
        aria-invalid={hasError || undefined}
        onChange={onChange}
        ref={ref}
        value={value}
        {...props}
      />
    );
  },
);
