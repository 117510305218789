import { styled } from '@/stitches/index.js';

export const Col = styled('div', {});

export const ColContainer = styled('div', {
  display: 'grid',
  gridColumnGap: '$16',
  gridRowGap: '$16',
  gridTemplateColumns: 'repeat(12, 1fr)',
  width: '100%',
});
