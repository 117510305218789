import { StyledTextFieldInput } from './StyledTextFieldInput.js';
import { styled } from '@/stitches/index.js';

export const TextFieldInputItems = styled('div', {
  [`& > ${StyledTextFieldInput}:first-child`]: {
    paddingLeft: '$8',
  },
  '& > *': {
    '&:last-child': {
      marginLeft: '0',
    },
    marginRight: '$8',
  },
  alignItems: 'center',
  display: 'flex',
  flex: '1',
  flexWrap: 'wrap',
  marginLeft: '$8',
});
