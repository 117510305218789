import { styled } from '@/stitches/index.js';
import TextareaAutosize from 'react-textarea-autosize';

// const minHeight = 54;

// 16px * 1.5
// const rowHeight = 24;
// const rowDifference = minHeight - rowHeight;

// min-height: ${rows
//   ? `${rows * rowHeight + rowDifference}px`
//   : `${minHeight}px`};

export const StyledTextFieldTextArea = styled(TextareaAutosize, {
  '&:disabled': {
    background: 'none',
  },
  '&:first-child, &:first-child:focus': {
    marginLeft: '0',
    paddingLeft: '$16',
  },
  '&:focus': {
    /* This is required because of the CSS reset */
    marginLeft: '0',
    marginRight: '0',
    paddingLeft: '$4',
    paddingRight: '$4',
  },
  '&:last-child, &:last-child:focus': {
    marginRight: '0',
    paddingRight: '$16',
  },
  backgroundColor: 'transparent',
  border: '0',
  borderRadius: 'inherit',
  flex: '1',
  fontFamily: '$inter',
  fontSize: '$16',
  fontWeight: '400',
  lineHeight: '1.5',
  margin: '0',
  minHeight: '$56',
  outline: 'none',
  padding: '$8 $4',
  resize: 'vertical',
});
