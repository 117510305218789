import { type ToastAction } from '../actions.js';
import { DEFAULT_STATE } from '../constants.js';
import { type ToastState } from '../reducer.js';
import { type ToastUtility } from '../types.js';
import { createContext, type Dispatch } from 'react';

const defaultFunction = () => {
  throw new Error('Must be called inside of Toast Provider');
};

type ToastContextType = {
  dispatch: Dispatch<ToastAction>;
  state: ToastState;
  toast: ToastUtility;
};

export const ToastContext = createContext<ToastContextType>({
  dispatch: defaultFunction,
  state: DEFAULT_STATE,
  // eslint-disable-next-line prefer-object-spread
  toast: Object.assign({}, defaultFunction, {
    content: defaultFunction,
    dismiss: defaultFunction,
    error: defaultFunction,
    loading: defaultFunction,
    promise: defaultFunction,
    remove: defaultFunction,
    success: defaultFunction,
    warning: defaultFunction,
  }),
});
