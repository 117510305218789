import { StyledTextFieldInputAddon } from './TextFieldInputAddon.styles.js';
import { forwardRef } from 'react';

type TextFieldInputAddonProps = React.ComponentPropsWithoutRef<'div'> & {
  readonly appended?: boolean;
};

export const TextFieldInputAddon = forwardRef(
  (
    { appended = false, children, color, ...props }: TextFieldInputAddonProps,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    return (
      <StyledTextFieldInputAddon
        appended={appended}
        color={color}
        ref={ref}
        {...props}
      >
        {children}
      </StyledTextFieldInputAddon>
    );
  },
);
