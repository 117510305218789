/**
 * This type will be updated over time as well add and remove flags.
 * Deprecated flags should be JSDoc'd as such.
 * Make sure your flags follow the naming convention outlined in the notion doc below.
 * @see https://www.notion.so/contrahq/Feature-Flags-e8a3301a4dd04201929fa0d58c34953f
 */
export type FeatureFlags = {
  /**
   * Used locally only for e2e tests to override user feature set by DB
   */
  ciaSubscriptions: boolean;
  /**
   * Gates the V2 of the inquiry flow from portfolio sites
   * See: https://app.posthog.com/feature_flags/11846
   */
  demandPortfolioInquiryV2: boolean;
  /**
   * Gates access to the 2023 Pro OG Image refresh
   * https://app.posthog.com/feature_flags/15143
   */
  proOGImageRefresh: boolean;

  /**
   * Enables 2023 Pro Services reskin
   * See: https://app.posthog.com/feature_flags/11846
   */
  proServicesReskin: 'control' | 'test';
};

type FeatureFlagRecord = {
  [key in keyof FeatureFlags]: FeatureFlags[key] extends infer KeyType
    ? KeyType extends boolean
      ? boolean
      : 'control' | 'test'
    : never;
};

const FEATURE_FLAG_DEFAULTS: FeatureFlagRecord = {
  ciaSubscriptions: true,
  demandPortfolioInquiryV2: true,
  proOGImageRefresh: true,
  proServicesReskin: 'test',
} as const;

export const getDefaultFeatureFlags = (): FeatureFlags => {
  return FEATURE_FLAG_DEFAULTS;
};
