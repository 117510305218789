import { FormFieldContext } from './context.js';
import { FormFieldError } from './FormFieldError.js';
import { FormFieldHelp } from './FormFieldHelp.js';
import { StyledFormField } from './styled/StyledFormField.js';
import { Highlighter } from '@/components/Highlighter/Highlighter.js';
import { useId } from '@react-aria/utils';
import { type ComponentPropsWithRef, useMemo } from 'react';

type FormFieldProps = ComponentPropsWithRef<'fieldset'> & {
  readonly hasError?: boolean;
  readonly isHighlighted?: boolean;
};

const FormField = ({
  children,
  hasError = false,
  isHighlighted = false,
  ...props
}: FormFieldProps) => {
  const errorAriaId = useId();

  const context = useMemo(() => {
    return {
      errorAriaId,
      hasError,
    };
  }, [errorAriaId, hasError]);

  return (
    <FormFieldContext.Provider value={context}>
      <Highlighter isHighlighted={isHighlighted}>
        <StyledFormField
          css={{ marginBottom: '$24' }}
          {...props}
        >
          {children}
        </StyledFormField>
      </Highlighter>
    </FormFieldContext.Provider>
  );
};

FormField.Error = FormFieldError;
FormField.Help = FormFieldHelp;

export { FormField };
