import { TextFieldContext } from '../context.js';
import { StyledTextFieldInput } from './StyledTextFieldInput.js';
import { TextFieldInputItems } from './StyledTextFieldInputItems.js';
import { StyledTextFieldTextArea } from './StyledTextFieldTextArea.js';
import { styled } from '@/stitches/index.js';
import { pxToRem } from '@/utilities/pxToRem.js';
import {
  type ComponentProps,
  type ForwardedRef,
  forwardRef,
  useContext,
} from 'react';

const Div = styled('div', {
  [`& > *:not(${StyledTextFieldInput}):not(${StyledTextFieldTextArea}):not(${TextFieldInputItems})`]:
    {
      '&:first-child': {
        marginLeft: '$12',
      },
      '&:lastChild': {
        marginRight: '$16',
      },
      marginLeft: '$4',
      marginRight: '$4',
    },
  '&[aria-disabled="true"]': {
    cursor: 'not-allowed',
  },
  alignItems: 'center',
  display: 'flex',
  fontFamily: '$inter',
  fontSize: '$16',
  lineHeight: 1.5,
  minHeight: '$36',
  paddingBottom: '$2',
  variants: {
    variant: {
      default: {},
      new: {
        [`& > *:not(${StyledTextFieldInput}):not(${StyledTextFieldTextArea}):not(${TextFieldInputItems})`]:
          {
            '&:first-child': {
              marginBottom: '$0',
            },
          },
        '& > div': {
          top: `calc(50% - ${pxToRem(6)})`,
        },
      },
    },
  },
});

export const StyledTextFieldInputGroup = forwardRef(
  (
    {
      ...props
    }: ComponentProps<typeof Div> & { readonly variant?: 'default' | 'new' },
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const { setIsHighlighted } = useContext(TextFieldContext);

    return (
      <Div
        {...props}
        onBlur={() => setIsHighlighted(false)}
        onFocus={() => setIsHighlighted(true)}
        ref={ref}
      />
    );
  },
);
