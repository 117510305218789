import { ToastContext } from '../contexts/ToastContext.js';
import { useContext } from 'react';

/**
 * Exposes functions to call toasts of different types along with toast
 * management utilities.
 *
 * Types:
 * `toast()`, `toast.error()`, `toast.loading()`, `toast.success()`,
 * `toast.content()`, and `toast.warning()`
 *
 * While all types of Toast allowed a 'Renderable' type to be passed in as a message,
 * whether it's JSX or a string, the `toast.content()` is more of an empty slate to allow
 * for Content Toasts to be designed as required.
 *
 * NOTE: The components used in Content Toasts should be "dumb". When the component passed to it
 * renders, it will not have access to the Relay data layer. This means you should prepare all data
 * in advance and pass it to the component when dispatching the Toast and not leave any of the data
 * fetching to the Content Toast component itself.
 * SEE: `useProjectServicePublished` and `Project.page` for example usage of the `content` variant.
 * Discussion around this approach: https://contra-work.slack.com/archives/C016ND05C2Z/p1639073303335900
 *
 * To display different toast types based on a promise use `toast.promise()`.
 *
 * Utilities:
 * `toast.dismiss()`, `toast.remove()`
 */
export const useToast = () => {
  const { toast } = useContext(ToastContext);

  return toast;
};
