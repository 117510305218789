import { styled } from '@/stitches/index.js';
import { type ComponentPropsWithoutRef } from 'react';

const Highlight = styled('div', {
  '& > :first-child': {
    background: '$yellow10',
    borderRadius: '$10',
    padding: '$12',
  },
  '@bp3': {
    '& > :first-child': {
      marginLeft: '-$12',
      marginRight: '-$12',
      marginTop: '-$12',
      width: 'calc(100% + $24)',
    },
  },
});

export const Highlighter = ({
  addContainer = false,
  children,
  isHighlighted = false,
  ...props
}: ComponentPropsWithoutRef<'div'> & {
  readonly addContainer?: boolean;
  readonly isHighlighted?: boolean;
}) => {
  return isHighlighted ? (
    <Highlight
      data-highlight="true"
      {...props}
    >
      {addContainer ? <div>{children}</div> : <>{children}</>}
    </Highlight>
  ) : (
    <>{children}</>
  );
};
