import { FormFieldContext } from './context.js';
import {
  StyledFormFieldHelp,
  StyledIcon,
} from './styled/StyledFormFieldHelp.js';
import { pxToRem } from '@/utilities/pxToRem.js';
import { type CSS } from '@stitches/react';
import {
  type ComponentPropsWithRef,
  type ComponentType,
  createElement,
  type ReactNode,
  useContext,
} from 'react';

type FormFieldHelpProps = {
  readonly children?: ReactNode;
  readonly css?: CSS;
  readonly icon?: ComponentType<ComponentPropsWithRef<'svg'>>;
};

const FormFieldHelp = ({ children, css, icon }: FormFieldHelpProps) => {
  const { hasError } = useContext(FormFieldContext);

  return (
    <StyledFormFieldHelp
      css={css}
      hasError={hasError}
    >
      {icon ? (
        <StyledIcon>
          {createElement(icon, { height: pxToRem(12), width: pxToRem(12) })}
        </StyledIcon>
      ) : null}
      {children}
    </StyledFormFieldHelp>
  );
};

FormFieldHelp.displayName = 'FormField.Help';

export { FormFieldHelp };
