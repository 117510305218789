import { PostHogContext } from '@/contexts/PosthogContext.js';
import { logger } from '@/services/logger.js';
import { useContext } from 'react';

const defaultContext = {
  featureFlags: {},
  setFeatureFlags: () => {},
};

export const usePostHog = (): PostHogContext => {
  const context = useContext(PostHogContext);

  if (context === null) {
    logger.warn('usePostHog is being used outside of PostHogProvider');

    return defaultContext;
  }

  return context;
};
