import { styled } from '@/stitches/index.js';

export const StyledFormFieldHelp = styled('div', {
  color: '$gray70',
  fontFamily: '$inter',
  fontSize: '$12',
  marginTop: '$4',
  paddingLeft: '$16',
  paddingRight: '$16',
  variants: {
    hasError: {
      true: {
        display: 'none',
      },
    },
  },
});

export const StyledIcon = styled('div', {
  alignItems: 'center',
  display: 'inline-flex',
  justifyContent: 'center',
  marginRight: '$4',
});
