/* eslint-disable id-length */
import { styled } from '@/stitches/index.js';

export const Section = styled('section', {
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  variants: {
    // TODO: Abstract into stitches config
    centerContent: {
      x: {
        alignItems: 'center',
      },
      xy: {
        alignItems: 'center',
        justifyContent: 'center',
      },
      y: {
        justifyContent: 'center',
      },
    },
    fullHeight: {
      true: {
        minHeight: '100vh',
      },
    },
    margin: {
      // Bottom
      b: {
        marginBottom: '$24',
        // eslint-disable-next-line canonical/sort-keys
        '@bp1': {
          marginBottom: '$32',
        },
        // eslint-disable-next-line canonical/sort-keys
        '@bp2': {
          marginBottom: '$40',
        },
      },
      // Top
      t: {
        marginTop: '$24',
        // eslint-disable-next-line canonical/sort-keys
        '@bp1': {
          marginTop: '$32',
        },
        // eslint-disable-next-line canonical/sort-keys
        '@bp2': {
          marginTop: '$40',
        },
      },
      // Margin top and bottom
      y: {
        marginBottom: '$24',
        marginTop: '$24',
        // eslint-disable-next-line canonical/sort-keys
        '@bp1': {
          marginBottom: '$32',
          marginTop: '$32',
        },
        // eslint-disable-next-line canonical/sort-keys
        '@bp2': {
          marginBottom: '$40',
          marginTop: '$40',
        },
      },
    },
  },
  width: '100%',
});
